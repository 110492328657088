import EventEmitter from 'eventemitter3';
import throttle from 'lodash.throttle';
import window from '../window';

export const event = new EventEmitter();
const initialViewport = { x: 0, y: 0, width: 1024, height: 768 };
export const VIEWPORT = 'VIEWPORT';
export const getViewPort = () =>
  window
    ? {
        x: window.pageXOffset,
        y: window.pageYOffset,
        width: window.innerWidth,
        height: window.innerHeight,
      }
    : initialViewport;

export const handleWindowResize = throttle(() => {
  event.emit(VIEWPORT, getViewPort());
}, 50);

export const SCROLL_TO = 'SCROLL_TO';
let scrollTo = '';
export const getScrollTo = () => scrollTo;
export const setScrollTo = newScrollTo => {
  scrollTo = newScrollTo;
  event.emit(SCROLL_TO, scrollTo);
};
export const resetScrollTo = () => (scrollTo = '');
