import { setScrollTo, handleWindowResize } from './src/utils/scroll/event';
import window from './src/utils/window';

export { default as wrapRootElement } from './src/state/reduxWrapper';

export const onClientEntry = () => {
  if (window) {
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('scroll', handleWindowResize);
    window.addEventListener('orientationchange', handleWindowResize);
  }
};

export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    setScrollTo(location.hash.replace('#', ''));
  }
};
